/*******************************
             Icon
*******************************/

/*--------------
   Font Files
---------------*/

$fontName: 'icons';
$src: url('fonts/#{$fontName}.eot?#iefix') format('embedded-opentype'),
  url('fonts/#{$fontName}.woff2') format('woff2'),
  url('fonts/#{$fontName}.woff') format('woff'),
  url('fonts/#{$fontName}.ttf') format('truetype'),
  url('fonts/#{$fontName}.svg#icons') format('svg');
$fallbackSRC: url('fonts/#{$fontName}.eot');

@font-face {
  font-family: 'Icons';
  src: $fallbackSRC;
  src: $src;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}

/*--------------
 Optional Files
---------------*/

/* Outline Icons */
$importOutlineIcons: true;
$outlineFontName: 'outline-icons';
$outlineSrc: url('fonts/#{$outlineFontName}.eot?#iefix')
    format('embedded-opentype'),
  url('fonts/#{$outlineFontName}.woff2') format('woff2'),
  url('fonts/#{$outlineFontName}.woff') format('woff'),
  url('fonts/#{$outlineFontName}.ttf') format('truetype'),
  url('fonts/#{$outlineFontName}.svg#icons') format('svg');
$outlineFallbackSRC: url('fonts/#{$outlineFontName}.eot');

/* Load & Define Icon Font */
@font-face {
  font-family: $outlineFontName;
  src: $outlineFallbackSRC;
  src: $outlineSrc;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}

/* Brand Icons */
$importBrandIcons: false;
$brandFontName: 'brand-icons';
$brandSrc: url('fonts/#{$brandFontName}.eot?#iefix') format('embedded-opentype'),
  url('fonts/#{$brandFontName}.woff2') format('woff2'),
  url('fonts/#{$brandFontName}.woff') format('woff'),
  url('fonts/#{$brandFontName}.ttf') format('truetype'),
  url('fonts/#{$brandFontName}.svg#icons') format('svg');
$brandFallbackSRC: url('fonts/#{$brandFontName}.eot');
/* Load & Define Brand Font */
@font-face {
  font-family: $brandFontName;
  src: $brandFallbackSRC;
  src: $brandSrc;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('fonts/ArialNarrow-Bold.woff2') format('woff2'),
    url('fonts/ArialNarrow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('fonts/ArialNarrow-BoldItalic.woff2') format('woff2'),
    url('fonts/ArialNarrow-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('fonts/ArialNarrow-Italic.woff2') format('woff2'),
    url('fonts/ArialNarrow-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Arial Narrow';
  src: url('fonts/ArialNarrow.woff2') format('woff2'),
    url('fonts/ArialNarrow.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('fonts/Arial-BoldItalicMT.woff2') format('woff2'),
    url('fonts/Arial-BoldItalicMT.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Arial';
  src: url('fonts/Arial-BoldMT.woff2') format('woff2'),
    url('fonts/Arial-BoldMT.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('fonts/Arial-ItalicMT.woff2') format('woff2'),
    url('fonts/Arial-ItalicMT.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Arial';
  src: url('fonts/ArialMT.woff2') format('woff2'),
    url('fonts/ArialMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Courier New';
  src: url('fonts/CourierNewPS-BoldItalicMT.woff2') format('woff2'),
    url('fonts/CourierNewPS-BoldItalicMT.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Courier New';
  src: url('fonts/CourierNewPS-BoldMT.woff2') format('woff2'),
    url('fonts/CourierNewPS-BoldMT.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Courier New';
  src: url('fonts/CourierNewPS-ItalicMT.woff2') format('woff2'),
    url('fonts/CourierNewPS-ItalicMT.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Courier New';
  src: url('fonts/CourierNewPSMT.woff2') format('woff2'),
    url('fonts/CourierNewPSMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bradley Hand';
  src: url('fonts/BradleyHandITC.woff2') format('woff2'),
    url('fonts/BradleyHandITC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Edeka Chalk';
  src: url('fonts/EdekaChalk-Regular.woff2') format('woff2'),
    url('fonts/EdekaChalk-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Bold.woff2') format('woff2'),
    url('fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-BoldItalic.woff2') format('woff2'),
    url('fonts/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-Regular.woff2') format('woff2'),
    url('fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-RegularItalic.woff2') format('woff2'),
    url('fonts/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy Black';
  src: url('fonts/Gilroy-Black.woff2') format('woff2'),
    url('fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Black';
  src: url('fonts/Gilroy-BlackItalic.woff2') format('woff2'),
    url('fonts/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy Light';
  src: url('fonts/Gilroy-Light.woff2') format('woff2'),
    url('fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Light';
  src: url('fonts/Gilroy-LightItalic.woff2') format('woff2'),
    url('fonts/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Have Heart One';
  src: url('fonts/HaveHeartOne.woff2') format('woff2'),
    url('fonts/HaveHeartOne.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Impact';
  src: url('fonts/Impact.woff2') format('woff2'),
    url('fonts/Impact.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kaufland';
  src: url('fonts/Kaufland-Bold.woff2') format('woff2'),
    url('fonts/Kaufland-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Kaufland';
  src: url('fonts/Kaufland-Regular.woff2') format('woff2'),
    url('fonts/Kaufland-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('fonts/MyriadPro-Bold.woff2') format('woff2'),
    url('fonts/MyriadPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('fonts/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Realize My Passion';
  src: url('fonts/RealizeMyPassion.woff2') format('woff2'),
    url('fonts/RealizeMyPassion.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rewe Pop';
  src: url('fonts/Rewe_Pop-Regular.woff2') format('woff2'),
    url('fonts/Rewe_Pop-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Display';
  src: url('fonts/Gilroy-Medium.woff2') format('woff2'),
    url('fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Display';
  src: url('fonts/Gilroy-MediumItalic.woff2') format('woff2'),
    url('fonts/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy Display';
  src: url('fonts/Gilroy-Bold.woff2') format('woff2'),
    url('fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Display';
  src: url('fonts/Gilroy-BoldItalic.woff2') format('woff2'),
    url('fonts/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Saira Condensed';
  src: url('fonts/SairaCondensed-Light.woff2') format('woff2'),
    url('fonts/SairaCondensed-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Saira Condensed';
  src: url('fonts/SairaCondensed-Black.woff2') format('woff2'),
    url('fonts/SairaCondensed-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Saira Stencil';
  src: url('fonts/SairaStencilOne-Regular.woff2') format('woff2'),
    url('fonts/SairaStencilOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rockness';
  src: url('fonts/Rockness.woff2') format('woff2'),
    url('fonts/Rockness.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DCC-Ash';
  src: url('fonts/DCC-Ash.woff2') format('woff2'),
    url('fonts/DCC-Ash.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neuton';
  src: url('fonts/Neuton-Regular.woff2') format('woff2'),
    url('fonts/Neuton-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neuton';
  src: url('fonts/Neuton-Bold.woff2') format('woff2'),
    url('fonts/Neuton-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica';
  src: url('fonts/Coolvetica.woff2') format('woff2'),
    url('fonts/Coolvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica-Compressed';
  src: url('fonts/Coolvetica-Compressed.woff2') format('woff2'),
    url('fonts/Coolvetica-Compressed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica-Condensed';
  src: url('fonts/Coolvetica-Condensed.woff2') format('woff2'),
    url('fonts/Coolvetica-Condensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coolvetica-Crammed';
  src: url('fonts/Coolvetica-Crammed.woff2') format('woff2'),
    url('fonts/Coolvetica-Crammed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HighVoltage Rough';
  src: url('fonts/HighVoltageRough.woff2') format('woff2'),
      url('fonts/HighVoltageRough.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Clearface Gothic LT Std';
  src: url('fonts/ClearfaceGothicLTStd-Bold.woff2') format('woff2'),
      url('fonts/ClearfaceGothicLTStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CaveatBrush';
  src: url('fonts/CaveatBrush-Regular.woff2') format('woff2'),
      url('fonts/CaveatBrush-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SedgwickAve';
  src: url('fonts/SedgwickAve-Regular.woff2') format('woff2'),
      url('fonts/SedgwickAve-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GlossAndBloom';
  src: url('fonts/GlossAndBloom.woff2') format('woff2'),
      url('fonts/GlossAndBloom.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('fonts/DINPro-Regular.woff2') format('woff2'),
      url('fonts/DINPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DINPro-black';
  src: url('fonts/DINPro-Black.woff2') format('woff2'),
      url('fonts/DINPro-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DINPro';
  src: url('fonts/DINPro-Bold.woff2') format('woff2'),
      url('fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
      url('fonts/HelveticaNeueLTStd-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('fonts/HelveticaNeueLTStd-It.woff2') format('woff2'),
      url('fonts/HelveticaNeueLTStd-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Helvetica Neue';
  src: url('fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
      url('fonts/HelveticaNeueLTStd-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('fonts/HelveticaNeueLTStd-BdIt.woff2') format('woff2'),
      url('fonts/HelveticaNeueLTStd-BdIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('fonts/HelveticaNeueCondensedLTStd-Cn.woff2') format('woff2'),
      url('fonts/HelveticaNeueCondensedLTStd-Cn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('fonts/HelveticaNeueCondensedLTStd-CnO.woff2') format('woff2'),
      url('fonts/HelveticaNeueCondensedLTStd-CnO.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('fonts/HelveticaNeueCondensedLTStd-BdCn.woff2') format('woff2'),
      url('fonts/HelveticaNeueCondensedLTStd-BdCn.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('fonts/HelveticaNeueCondensedLTStd-BdCnO.woff2') format('woff2'),
      url('fonts/HelveticaNeueCondensedLTStd-BdCnO.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: url('fonts/Exo2-Regular.woff2') format('woff2'),
      url('fonts/Exo2-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: url('fonts/Exo2-SemiBold.woff2') format('woff2'),
      url('fonts/Exo2-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sean Becker-Heavy';
  src: url('fonts/SeanBecker-Heavy.woff2') format('woff2'),
      url('fonts/SeanBecker-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.woff2') format('woff2'),
      url('fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Italic.woff2') format('woff2'),
      url('fonts/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.woff2') format('woff2'),
      url('fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-BoldItalic.woff2') format('woff2'),
      url('fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('fonts/Lato-Black.woff2') format('woff2'),
      url('fonts/Lato-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('fonts/Lato-BlackItalic.woff2') format('woff2'),
      url('fonts/Lato-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Regular.woff2') format('woff2'),
      url('fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Italic.woff2') format('woff2'),
      url('fonts/OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Bold.woff2') format('woff2'),
      url('fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-BoldItalic.woff2') format('woff2'),
      url('fonts/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('fonts/OpenSans-Light.woff2') format('woff2'),
      url('fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('fonts/OpenSans-LightItalic.woff2') format('woff2'),
      url('fonts/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-Regular.woff2') format('woff2'),
      url('fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-Italic.woff2') format('woff2'),
      url('fonts/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-Bold.woff2') format('woff2'),
      url('fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-BoldItalic.woff2') format('woff2'),
      url('fonts/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('fonts/Raleway-Light.woff2') format('woff2'),
      url('fonts/Raleway-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Light';
  src: url('fonts/Raleway-LightItalic.woff2') format('woff2'),
      url('fonts/Raleway-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular.woff2') format('woff2'),
      url('fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Light.woff2') format('woff2'),
      url('fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Bold.woff2') format('woff2'),
      url('fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Italic.woff2') format('woff2'),
      url('fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-BoldItalic.woff2') format('woff2'),
      url('fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Apis';
  src: url('fonts/Apis-Regular.woff2') format('woff2'),
      url('fonts/Apis-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apis';
  src: url('fonts/Apis-Italic.woff2') format('woff2'),
      url('fonts/Apis-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Apis';
  src: url('fonts/Apis-Bold.woff2') format('woff2'),
      url('fonts/Apis-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Apis';
  src: url('fonts/Apis-BoldItalic.woff2') format('woff2'),
      url('fonts/Apis-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Apis-Black';
  src: url('fonts/Apis-Black.woff2') format('woff2'),
      url('fonts/Apis-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apis-Black';
  src: url('fonts/Apis-BlackItalic.woff2') format('woff2'),
      url('fonts/Apis-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Apis-Light';
  src: url('fonts/Apis-Light.woff2') format('woff2'),
      url('fonts/Apis-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apis-Light';
  src: url('fonts/Apis-LightItalic.woff2') format('woff2'),
      url('fonts/Apis-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik Light';
  src: url('fonts/Rubik-Light.woff2') format('woff2'),
      url('fonts/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik Medium';
  src: url('fonts/Rubik-Medium.woff2') format('woff2'),
      url('fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik-Regular.woff2') format('woff2'),
      url('fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik-Bold.woff2') format('woff2'),
      url('fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik Semibold';
  src: url('fonts/Rubik-SemiBold.woff2') format('woff2'),
      url('fonts/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DraegerSans Light';
  src: url('fonts/DraegerSans-Light.woff2') format('woff2'),
      url('fonts/DraegerSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DraegerSans';
  src: url('fonts/DraegerSans-Regular.woff2') format('woff2'),
      url('fonts/DraegerSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DraegerSans';
  src: url('fonts/DraegerSans-Italic.woff2') format('woff2'),
      url('fonts/DraegerSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DraegerSans';
  src: url('fonts/DraegerSans-Bold.woff2') format('woff2'),
      url('fonts/DraegerSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DraegerWalbaum';
  src: url('fonts/DraegerWalbaum-Regular.woff2') format('woff2'),
      url('fonts/DraegerWalbaum-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DraegerWalbaum';
  src: url('fonts/DraegerWalbaum-Italic.woff2') format('woff2'),
      url('fonts/DraegerWalbaum-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DraegerWalbaum';
  src: url('fonts/DraegerWalbaum-Bold.woff2') format('woff2'),
      url('fonts/DraegerWalbaum-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothicLT';
  src: url('fonts/TradeGothicLT.woff2') format('woff2'),
      url('fonts/TradeGothicLT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothicLT';
  src: url('fonts/TradeGothicLT-Oblique.woff2') format('woff2'),
      url('fonts/TradeGothicLT-Oblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TradeGothicLT';
  src: url('fonts/TradeGothicLT-Bold.woff2') format('woff2'),
      url('fonts/TradeGothicLT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothicLT';
  src: url('fonts/TradeGothicLT-BoldOblique.woff2') format('woff2'),
      url('fonts/TradeGothicLT-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik Black';
  src: url('fonts/Graphik-Black.woff2') format('woff2'),
      url('fonts/Graphik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('fonts/Graphik-Semibold.woff2') format('woff2'),
      url('fonts/Graphik-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('fonts/Graphik-Regular.woff2') format('woff2'),
      url('fonts/Graphik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Light';
  src: url('fonts/Graphik-Light.woff2') format('woff2'),
      url('fonts/Graphik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Medium';
  src: url('fonts/Graphik-Medium.woff2') format('woff2'),
      url('fonts/Graphik-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cairo';
  src: url('fonts/Cairo-Bold.woff2') format('woff2'),
      url('fonts/Cairo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('fonts/Cairo-Regular.woff2') format('woff2'),
      url('fonts/Cairo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo Light';
  src: url('fonts/Cairo-Light.woff2') format('woff2'),
      url('fonts/Cairo-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/Barlow-Bold.woff2') format('woff2'),
    url('fonts/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/Barlow-BoldItalic.woff2') format('woff2'),
    url('fonts/Barlow-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/Barlow-Regular.woff2') format('woff2'),
    url('fonts/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/Barlow-Italic.woff2') format('woff2'),
    url('fonts/Barlow-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Tandelle';
  src: url('fonts/Tandelle-Bold.woff2') format('woff2'),
    url('fonts/Tandelle-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Tandelle';
  src: url('fonts/Tandelle-BoldItalic.woff2') format('woff2'),
    url('fonts/Tandelle-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Tandelle';
  src: url('fonts/Tandelle-Regular.woff2') format('woff2'),
    url('fonts/Tandelle-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tandelle';
  src: url('fonts/Tandelle-Italic.woff2') format('woff2'),
    url('fonts/Tandelle-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Textile';
  src: url('fonts/Textile-Regular.woff2') format('woff2'),
    url('fonts/Textile-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('fonts/TitilliumWeb-Bold.woff2') format('woff2'),
    url('fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('fonts/TitilliumWeb-Black.woff2') format('woff2'),
    url('fonts/TitilliumWeb-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('fonts/TitilliumWeb-Regular.woff2') format('woff2'),
    url('fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TitilliumWeb';
  src: url('fonts/TitilliumWeb-Light.woff2') format('woff2'),
    url('fonts/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Siemens Sans Global';
  src: url('fonts/SiemensSansGlobal-Bold.woff2') format('woff2'),
    url('fonts/SiemensSansGlobal-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Siemens Sans Global';
  src: url('fonts/SiemensSansGlobal-BoldItalic.woff2') format('woff2'),
    url('fonts/SiemensSansGlobal-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Siemens Sans Global';
  src: url('fonts/SiemensSansGlobal-Regular.woff2') format('woff2'),
    url('fonts/SiemensSansGlobal-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Siemens Sans Global';
  src: url('fonts/SiemensSansGlobal-Italic.woff2') format('woff2'),
    url('fonts/SiemensSansGlobal-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Hind';
  src: url('fonts/Hind-Regular.woff2') format('woff2'),
    url('fonts/Hind-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('fonts/Hind-Bold.woff2') format('woff2'),
    url('fonts/Hind-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('fonts/ITCAvantGardeGothic-Regular.woff2') format('woff2'),
      url('fonts/ITCAvantGardeGothic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('fonts/ITCAvantGardeGothic-Light.woff2') format('woff2'),
      url('fonts/ITCAvantGardeGothic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('fonts/ITCAvantGardeGothic-Bold.woff2') format('woff2'),
      url('fonts/ITCAvantGardeGothic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('fonts/Oswald-Regular.woff2') format('woff2'),
      url('fonts/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('fonts/Oswald-Light.woff2') format('woff2'),
      url('fonts/Oswald-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('fonts/Oswald-Bold.woff2') format('woff2'),
      url('fonts/Oswald-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'fonts/Mada-Light';
  src: url('fonts/Mada-Light.woff2') format('woff2'),
      url('fonts/Mada-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mada';
  src: url('fonts/Mada-Regular.woff2') format('woff2'),
      url('fonts/Mada-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mada';
  src: url('fonts/Mada-Bold.woff2') format('woff2'),
      url('fonts/Mada-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'fonts/Mada-Black';
  src: url('fonts/Mada-Black.woff2') format('woff2'),
      url('fonts/Mada-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
