.ui.range {
    width: 100%;
}

.ui.range .inner {
    margin: 0 10px 0 10px;
    height: 20px;
    position: relative;
}

.ui.range .inner:hover {
    cursor: pointer;
}

.ui.range .inner .track {
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    top: 9px;
    left: 0;
    background-color: rgba(0,0,0,.05);
}

.ui.inverted.range .inner .track {
    background-color: rgba(255,255,255,.08);
    width: 201px;
}

.ui.range .inner .track-fill {
    position: absolute;
    width: 0;
    height: 4px;
    border-radius: 4px;
    top: 9px;
    left: 0;
    background-color: #1b1c1d;
}

.ui.inverted.range .inner .track-fill {
    background-color: #545454;
}

.ui.range .inner .thumb {
    position: absolute;
    top: -8px;
    height: 20px;
    width: 20px;
    background: #fff linear-gradient(transparent, rgba(0, 0, 0, 0.05));
    border-radius: 100%;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15),0 0 0 1px rgba(34,36,38,.15) inset;
}
