.rc-color-picker-panel-inner {
    box-shadow: none !important;
    border: none !important;
}

.popover {
    max-width: none !important;
} 

.ui.vertical.menu {
    width: auto;
}
