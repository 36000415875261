.slider {

  $size: 10px;

  width: 100%;
  display: flex;
  height: $size*2.5;

  .control {
    position: relative;
    width: 100%;

    .track {
      height: $size;
      width: 100%;
      margin-left: -$size*0.5;
      padding-right: $size;
      box-sizing: content-box;
      border-radius: $size*0.5;
    }

    .scrubber {

      position: absolute;

      margin-left: -$size*0.5;
      margin-top: $size*0.5;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      .label {
        position: absolute;
        top: -$size;
        left: $size*2;
        background: #eee;
        padding: 3px;
        z-index: 1000;
        white-space: nowrap;
      }

      .arrow {
        $arrow: $size*0.5;
        position: absolute;
        content: "";
        display: block;
        width: 0;
        height: 0;
        top: -$arrow;
        border-left: $arrow solid transparent;
        border-right: $arrow solid transparent;
        border-bottom: $arrow solid black;
      }

      width: $size;
      height: $size;
    }

  }

  .button {
    width: $size*2.5;
    height: $size*2.5;
    margin-left: $size*2 !important;
    margin-right: 0 !important;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;

    > .icon {
      position: absolute;
      left: 3px;
      top: 5px;
      font-size: 10px;
    }
  }

}
