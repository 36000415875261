/*@import "assets/fonts/fonts.css";*/

$color-background: #272727;
$light-grey: #f1f1f1;
$color-gray: #272727;
$red: #ff0059;

.ui.inverted.segment {
  background: $color-background;
}

.workspace {
  position: relative;
}

.debug {
  width: 5px;
  height: 5px;
  background: red;
  border-radius: 50%;
}

.dnd-hover {
  border: 2px solid #000000 !important;
  margin: -2px;
}

.ui.tabular.menu + .attached:not(.top).segment.editor-control--panes {
  margin-top: 1rem;
}

.viewport {
  position: fixed;
  height: 100vh;
  width: calc(69.5vw - 3.5rem + 10px); // Compensate for the sidebar
}

main {
  user-select: none !important;
}

.sticker-icon {
  min-width: 30px;
  height: 30px;
  display: inline-block;
  object-fit: contain;
}

.sticker-input {
  display: flex;
  align-items: center;
  padding: 0.5rem;

  .name {
    flex-grow: 1;
    width: 0;
  }
}

.sticker-input .ui[class*="right floated"].button {
  margin-left: auto;
}

.sticker-input .plus.icon {
  cursor: pointer;
}

.icon-margin,
.sticker-icon {
  margin-right: 10px;
}

.spacer {
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
}

.drag-preview {
  border-top: 1px solid #dbdbdb;
}

.draggable {
  -webkit-transition: padding 0.1s linear;
  transition: padding 0.1s linear;
  padding-top: 0;
  padding-bottom: 0;
}

.customicon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block !important;
  width: 16px !important;
  height: 16px !important;
  margin: 0 !important;
}



.customicon.customicon-fill, .customicon.customicon-fillGlobal, .customicon.customicon-fillAndBlend {
  background-image: url(./icons/fill.svg);
}

.customicon.customicon-stroke {
  background-image: url(./icons/stroke.svg);
}

.customicon.customicon-opacity {
  background-image: url(./icons/opacity.svg);
}

.customicon.customicon-align-x-start {
  background-image: url(./icons/align-1.svg);
}

.customicon.customicon-align-x-center {
  background-image: url(./icons/align-2.svg);
}

.customicon.customicon-align-x-end {
  background-image: url(./icons/align-3.svg);
}

.customicon.customicon-align-y-start {
  background-image: url(./icons/align-4.svg);
}

.customicon.customicon-align-y-center {
  background-image: url(./icons/align-5.svg);
}

.customicon.customicon-align-y-end {
  background-image: url(./icons/align-6.svg);
}

body {
  color: #000;
  font-family: 'Gilroy Display', sans-serif !important;
}

.ui.button {
  font-weight: normal;
  background: #fff;
  color: #282e35;
}

.dnd-hover {
  border: 1px dashed black;
}

.ui.page.modals.dimmer.transition.visible.active {
  display: flex !important;
}

/*
.grabbable {
  cursor: url(./icons/grabbale.svg);
  cursor: -webkit-grab;
  cursor:    -moz-grab;
  cursor:         grab;
}

.grabbing {
  cursor: url(./icons/grabbing.svg);
  cursor: -webkit-grabbing;
  cursor:    -moz-grabbing;
  cursor:         grabbing;
}
*/

.workspace {
  overflow: hidden;
}

svg, .overlay {
  font-family: Gilroy Display, Arial, sans-serif;
}

.image-item {
  background: #fff;
  text-align: left;
  font-family: "Gilroy Display", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.image-item .content {
  max-width: 100px;
  overflow: hidden;
}

.image-item .content .header {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-list-template {
  .image-item .content {
    max-width: none;
    .header {
      max-width: none;
    }
    .ui.label {
      display: block;
      margin: 2px 0;
    }
  }

}

.image-item {
  border: 1px solid transparent;
  border-radius: 0 !important;
  position: relative;
}

.image-item.active {
  background: #ddd;
  border: 1px solid #000;
}

.image-item.detail .content {
  max-width: none;
}

.image-item.detail {
  display: flex;
  width: 300px;
  align-items: center;
}

.image-item.detail > div {
  margin-left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 60px;
  justify-content: center;
}

.image-item.detail .content {
  flex-direction: row;
}

.image-item.detail .content .label {
  display: inline-flex;
}

.image-item.detail > div {
  display: flex;
}

.image-item.detail .header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.list > .item.active {
  background: #F3F4F5;
}

.background-none {
  background: url(./icons/no-background.svg) no-repeat !important;
  background-size: contain;
}

$color-highlight: #000;

.highlight-selected {
  border: 3px solid white !important;
  outline: 3px solid black !important;
}

.highlight-none {
  /* only for spacing */
  border: 3px solid transparent !important;
  outline: 3px solid transparent !important;
}

.highlight-grey {
  /* only for spacing */
  border: 3px solid white !important;
  outline: 3px solid #d5d5d5 !important;
}

.highlight-pulse {
  box-shadow: 0 0 0 $color-highlight;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($color-highlight, 0.9);
  }
  99% {
    box-shadow: 0 0 0 10px rgba($color-highlight, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($color-highlight, 0);
  }
}

img.svg {
  background: #d5d5d5 !important;
}

.highlight-shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
}

.button-swatch {
  display: inline-block;
  margin: 5px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 30px !important;
  height: 30px !important;
}

.spread-grid {
  pointer-events: none;
}

.ui.label {
  background-color: transparent;
}

.ui.menu.vertical {
  border-radius: 0 !important;
}

.label.entry {
  float: none !important;
  margin-left: 0 !important;
}

.templates-panel {
  min-height: 500px;
}

.ui.grid > .row {
  padding: 0 !important;
}

.scrolling {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  flex-basis: 0;
}

.tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(30.5% - 1.5rem - 10px);

  .grid {
    .column {
      padding-top: 4px !important;
      &:first-child {
        padding: 0 !important;
        width: 0 !important;

        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: -5rem;
          top: 0;
          bottom: 0;
          right: 0;
          background: $color-background;
        }

        .ui.vertical.tabular.menu {
          top: 4rem;
          right: 5rem;
          position: absolute;

          .item {
            height: 4rem;
            width: 5rem;
            border-top-left-radius: 2rem !important;
            border-bottom-left-radius: 2rem !important;
            justify-content: center;
            align-items: center;
            border: none !important;
            margin: 0 !important;
            color: #fff;
            display: flex;
            flex-direction: column;

            &.active {
              color: $color-gray;
            }

            & > i.icon {
              margin: 0 0 8px 0;
            }

            & > .title {
              text-transform: uppercase;
              font-size: 80%;
              text-align: center;
              line-height: 120%;
            }

          }
        }

      }
      &:last-child {
        padding-left: 2px;

        background: #fff;
        width: 100% !important;
      }
    }

    .menu {
      border: none !important;
    }

    .ui.celled.list > .item {
      border-top: none;
    }

    .ui.segment {
      padding-top: 0;
      padding-bottom: 0;
    }

  }

  & .list .item {
    margin-bottom: 10px;
  }

  & > .main {
    display: flex;
    flex-direction: column;
  }

  & > .main > .menu {
    flex-basis: 0;
  }

  & .ui.table:last-child {
    margin: 0;
  }

  .ui.horizontal.list:not(.celled) > .item:first-child,
  & .ui.list .item {
    vertical-align: top;
    padding: 0;
    margin: 5px !important;
  }

  & .ui.table.sections .ui.table td {
    padding: 2px;
  }

  > .flex, > .grid {
    flex-grow: 1;
  }

  > .tab {
    flex-grow: 1;
  }

  .ui.tab.active {
    display: flex !important;
  }

}

.ui.grid > .row.stretched {
  flex-grow: 1;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex.vertical {
  flex-direction: row;
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.ui.grid {
  margin: 0 !important;
}

.ui.tab.active.flex, .ui.tab.open.flex {
  display: flex !important;
}

.ui.secondary.menu .ui.secondary.menu:not(.vertical) .item {
  padding: 2px 0;
}

.ui.table {

  thead {
    tr:first-child {
      border-radius: 8px 8px 0 0;
      border: 0;
    }

    th {
      background: $light-grey;
      border: 0;
    }
  }

  tbody {
    td {
      font-family: 'Gilroy Light', sans-serif;
    }
  }
}

.section-panel .ui.table td {
  padding: 0;
}

.section-panel .ui.table {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.filter-input .item {
  padding: 0 !important;
  margin: 0 !important;
}

.filter-input .item label {
  line-height: 40px;
  min-width: 40px;
}

.usage {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #264c59;
  color: white;
  border-radius: 20px;
  padding: 5px 8px;
}

foreignObject {
  overflow: visible;
}

.DraftEditor-root {
  font-size: 0;
}

.toolbar {
  //height: calc(100% +  11px);
  width: calc(100% - 37.5% - 6px);
  min-height: 60px;
  padding: 0.5rem 1.5rem;

  .item, .menu {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .horizontally.fitted.item {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  .item > div,
  [role="listbox"] {
    margin-right: 1em;
  }

  .ui.selection.dropdown {
    min-width: 0;
  }

  .richtext {

    .ui.button {
      > .icon:not(.button) {
        opacity: 0.4;
      }
      &.active > .icon:not(.button) {
        opacity: 1;
      }
    }
  }

}

.workspace-wrapper > .toolbar {
  border-radius: 10px;
  position: fixed;
  z-index: 6;
  border: none !important;
  min-height: 3.6rem;
  top: 1rem;
  left: 1rem;

  .ui.button {
    background: transparent;
  }
}

.editor-control--panes-wrapper {
  clear: both;

  .ui.secondary.menu {
    overflow-x: scroll;
    margin: 1em 1em -1em 1em;
    padding-bottom: 1em;
    padding-top: 1em;
    box-shadow: inset -8px 0px 5px -7px rgba(0, 0, 0, 0.3)
  }
}

.ui.attached.segment.tab {
  border: none;
}

.global-navigation--menu.ui.secondary.menu {

  .item {
    padding-left: 0;
    padding-right: 0;

    button:not(.primary) {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

/*******************************
         Theme Overrides
*******************************/

.ui.icon.button {
  &:not(.compact) {
    padding-left: 24px;
    padding-right: 24px;
  }
  &.compact {
    padding: 0;
    min-width: 35px;
    min-height: 35px;
    border-radius: 100%;
  }
}

.buttons {
  .ui.dropdown > .dropdown.icon {
    display: flex;
    align-items: center;
  }

  .ui.icon.button {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.ui.form input[type="text"] {
  font-size: inherit;
}

.ui.primary.button, .ui.primary.buttons .button {
  background: $red;
  font-weight: bold;
  z-index: 1;
  position: relative;

  &:after {
    content: '';
    transition: .2s;
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    top: 0;
    left: 0;
    border-radius: 20px;
    opacity: 0.5;
    z-index: -1;
  }

  &:hover:after {
    filter: blur(0.4rem);
    transition: 0.5s;
  }

  &:hover {
    background: lighten($red, 5);
  }
}

.button.primary:after {
  position: absolute;
  box-shadow: 10px 10px 10px inherit;
}

div[draggable="true"] {
  border-radius: 20px;
}

div[draggable="true"] > .ui.button {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  background: white;

  &:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
  }
}

.ui.button.primary:disabled {
  background: #cacbcd;
}

hr {
  background: $lightGrey;
  height: 1px;
  border: 0;
}

i.huge.icon, i.huge.icons {
  min-width: 5rem;
  min-height: 5rem;
}

.ui.menu:not(.vertical) > .menu {
  width: 100%;
}

.sticker-input > button {

  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: none;
  text-align: left;
  font-family: 'Gilroy', sans-serif;
  outline: none;

  > {
    .number {
      display: inline-block;
      font-weight: bold;
      width: 30px;
    }
    .image {
      $scale: 0.7;
      display: inline-block;
      border: 2px solid #aaa;
      padding: 2px;
      width: 50px * $scale;
      height: 70px * $scale;
      margin-right: 20px;
      &.double {
        width: 100px * $scale;
      }
      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
      .background-none {
        width: 100%;
        height: 100%;
        background-size: contain;
      }
    }
  }

  .input {
    width: 100%;
  }

}

.menu.field {
  display: inline-flex;
  justify-content: left;
  min-width: 60px;
}

.ui.vertical.menu .item.flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.item.job {

  background: #a4bfc8;

  &.job-queued {
    background: #a4bfc8;
  }
  &.job-finished {
    background: #8ec89d;
  }
  &.job-exists {
    background: #aaa;
  }
  &.job-working {
    background: #f4ebd0;
  }
  &.job-error {
    background: #f2af9e;
  }

  border-radius: 5px;
  display: flex;
  flex-direction: row;

  padding: 1rem !important;
  align-items: center;

  .title {
    font-weight: bold;

    .time {
      margin-left: .5rem;
      font-weight: normal;
      opacity: .6;
    }
  }

  .first, .last {
    width: 50px;
    justify-content: center;
    display: flex;
  }

  .middle {
    flex-grow: 1;
  }

  .ui.progress {
    margin: 0.5rem 0 1.5rem;
    > .label {
      margin-top: 0.5rem;
    }
  }

  .eta {
    right: 0;
    color: #fff;
    z-index: 1;
  }

  .links {
    margin-top: .5rem;
  }

}

.tabs .ui.list .item.job {
  margin: 0 5px 5px 0 !important;
}

.create-job, .text-replace, .builder {

  label {
    min-width: 200px;
  }

}

.btn-group {
  .ui.button {
    color: rgba(0, 0, 0, 0.3);
    &.active {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.element-button {
  margin-bottom: 10px;
}

table .ui.horizontal.list {
  padding: 0 !important;
}

.item.spinner {
  width: 4rem;
}

.page-margins {
  margin-top: 20px;
  width: 200px;
  height: 100px;
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;

  $width: 60px;

  > * {
    position: absolute !important;
    width: $width;
  }

  .top {
    top: 0;
    left: 50%;
    margin-left: -$width*0.5;
  }

  .left {
    left: 0;
  }
  .right {
    right: 0;
  }

  .bottom {
    bottom: 0;
    left: 50%;
    margin-left: -$width*0.5;
  }

}

.flex {
  display: flex;

  &.end {
    align-items: flex-end;
  }
}


#fakeInput {
  display: none;
}


.elements-tree {
  box-shadow: 0px 0px 20px #0000001f;
  position: absolute;
  left: 15px;
  top: 130px;
  z-index: 5;
  min-width: 230px;
  background: white;
  padding: 1rem 0;
  border-radius: 10px;
  overflow: auto;

  .draggable-element {
    border-radius: 0px;
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }
  }

  .item {
    padding: .4rem 1rem !important;
    display: flex;
    width: 100%;
    border-radius: 0px;

    &.active {
      background: #f1f1f1;
    }

    .node-icon {
      font-size: .8rem;
      opacity: .5;
    }

    .content {
      width: 100%;

      .header {
        font-weight: normal !important;
        color: rgb(113, 113, 113) !important;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .lock-icon {
          opacity: .5;
          font-size: .8rem;

          &.locked {
            opacity: 1;
          }
        }
      }
    }
  }
}


.context-menu {
  position: fixed;
  z-index: 10;
  margin-top: 0;
  min-width: 300px;

  .menu {
    min-width: 100%;
  }
}

.album-link {
  min-height: 150px;
}


.field.section-triangle {
  float: left;
}

.field.section-title {
  display: block;
  min-width: 200px;
  min-height: 20px;
}

.ui.page.dimmer {
  z-index: 2000;
}

.folder-list-template, .folder-list-image {
  .folder-item {
    float: left;
  }
}

// Fix for an incompatibility between semantic and bootstrap
.ui.modal {
  left: auto;
  top: auto;
  width: auto;
  height: auto;
}

.submenu-button {
  cursor: pointer;

  .dropdown.icon:before {
    content: "\f0da"/*rtl:"\f0d9"*/;
  }
}

.submenu-content {
  position: fixed;
  top: 0;
  width: 300px;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.album-preview-wrapper {
  width: 100%;
  height: 100vh;

  .viewport {
    width: 100%;
  }

  .top-controls {
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: end;
    pointer-events: none;

    .image-loading-indicator {
      z-index: 1;
      font-size: .75rem;
      background: white;
      padding: 1rem 1.5rem;
      border-radius: 8px;
      max-width: 200px;

      span {
        margin-left: .5rem;
      }
    }
  }

  .spread-controls {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    pointer-events: none;

    > div {
      flex: 1;
      text-align: center;
      pointer-events: auto;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }

    .preview-metadata {
      background: white;
      padding: 1rem 1.5rem;
      border-radius: 8px;
      max-width: 200px;
    }
  }
}

.comment-form-wrapper, .comment-wrapper {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 20px #0000003d;
  font-size: 14px;
  overflow-wrap: break-word;

  .text {
    white-space: pre-line;
  }

  .comment {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      line-height: 1;
    }

    &-author {
      flex: 1;
      word-break: break-word;
      display: flex;
      align-items: center;
      line-height: normal;
    }

    &-close {
      display: flex;
      align-items: center;
    }

    &-close-button {
      cursor: pointer;
      padding-top: 0.1rem;
    }

    &-text-divider {
      border-top: 1px solid #ccc;
      margin: 0.5rem 0;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-action-buttons {
      display: flex;
      align-items: center;
    }
  }
}

.pulsating-circle {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #FE1251;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FE1251;
    border-radius: 15px;
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@import "selector";

@import "element";

@import "handles";

@import "util";

@import "color-picker";


/* duct-tape fix for this weird bug: https://github.com/facebook/create-react-app/issues/11773 */
body > iframe {
  display: none;
}
