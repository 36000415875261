.handles {
    &.frame {
        pointer-events: none;
    }

    &.operations {
        pointer-events: all;
    }

    * {
        fill: none;
        vector-effect: non-scaling-stroke;
    }

    .border {
        pointer-events: none;
        fill: none;
        stroke: #a1f6ff;
        stroke-width: 1px;
    }

    .move {
        cursor: move;
        fill: none;
    }

    .resize {
        fill: #a1f6ff;
    }

    .scale {
        fill: #ffbca8;
    }

    .rotate.top.left {
        cursor: url(./icons/rotate-0.svg) 16 16, auto;
    }
    
    .rotate.top.right {
        cursor: url(./icons/rotate-1.svg) 16 16, auto;
    }
    
    .rotate.bottom.right {
        cursor: url(./icons/rotate-2.svg) 16 16, auto;
    }
    
    .rotate.bottom.left {
        cursor: url(./icons/rotate-3.svg) 16 16, auto;
    }

    //  For sticker and image-manipulation or during editing text: use green color to indicate "inside" transform
    &.operations.inside.type-Text, &.operations.inside.type-Image, &.operations.sticker {
        .resize {
            fill: #adff68;
        }

        .border {
            stroke: #adff68;
        }

        .move {
            stroke: #adff68;
        }

        .scale {
            fill: #adff68;
        }

    }

}
